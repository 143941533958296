var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"paginated":"","flat":"","hide-table-header":"","columns":_vm.columns,"items":_vm.creditLineProvisions,"loading":_vm.loading},scopedSlots:_vm._u([{key:"_iteration",fn:function(ref){
var item = ref.item;
var iteration = ref.iteration;
return [_vm._v(" "+_vm._s(item.transaction ? item.transaction.reference : iteration)+" ")]}},{key:"createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.createdAt,'DD/MM/YYYY \à HH:mm'))+" ")]}},{key:"otm",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.otm.toUpperCase())+" ")]}},{key:"amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.amount))+" ")]}},{key:"_balance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(_vm.getProvisionBalance(item)))+" ")]}},{key:"state",fn:function(ref){
var provision = ref.item;
return [_c('v-text',{attrs:{"bg":_vm.states[provision.state].bg,"color":_vm.states[provision.state].color,"px":"2","py":"1","weight":"semibold","rounded":"lg"}},[_vm._v(" "+_vm._s(_vm.states[provision.state].label)+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }