







































import Vue from 'vue'
import CreditLineProvision from '../../../../../store/models/CreditLineProvision'

export default Vue.extend({
  data() {
    return {
      loading: false,
      states: {
        pending: { label: 'En attente', color: 'yellow-600', bg: 'yellow-200' },
        successeed: { label: 'Succès', color: 'green-100', bg: 'green-300' },
        failed: { label: 'Échec', color: 'red-100', bg: 'red-300' }
      },
      columns: [
        {
          label: 'N° transaction',
          field: '_iteration'
        },
        {
          label: 'Ligne de crédit',
          field: 'creditLine.name'
        },
        {
          label: 'Date/Heure',
          field: 'createdAt'
        },
        {
          label: 'Montant',
          field: 'amount'
        },
        {
          label: 'Solde disponible',
          field: '_balance'
        },
        {
          label: 'Opérateur Mobile',
          field: 'otm',
          align: 'center',
          width: 'w-64'
        },
        {
          label: 'Statut',
          field: 'state',
          align: 'center'
        }
      ]
    }
  },

  computed: {
    creditLineProvisions(): CreditLineProvision[] {
      return CreditLineProvision.forCurrentOrganization()
    }
  },

  created() {
    this.loading = true
    CreditLineProvision.api()
      .fetchForCurrentOrganization({
        'state!': 'canceled',
        include: ['creditLine', 'transaction']
      })
      .then((response: any) => {
        this.loading = false
      })
      .catch((error: Error) => {
        console.error(error)
        this.loading = false
      })
  },

  methods: {
    getProvisionBalance(item: any) {
      const balance =
        item.otm === 'mtn'
          ? item.creditLine.mtnAmount
          : item.creditLine.moovAmount

      return balance > 0 ? balance : 0
    }
  }
})
